@import "theme/_variables";

$lightGray: #f2f2f2;
$darkGray: #53565a;
$darkHighlight: #cde9e3;
$lightHightlight: #eef7f5;
$highlightBorder: #b5e3d8;

@mixin checkMarkSvg($color: "limegreen") {
  $svg: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$color}' viewBox='0 0 13 10'><path fill='#{$color}' d='M2.6628 3.9784a1 1 0 00-1.44 1.38l3.226 3.3358a1.0001 1.0001 0 00.72.31 1.0002 1.0002 0 00.72-.3l6.78-7A1.0055 1.0055 0 0012.9529.99a1.0046 1.0046 0 00-1.0183-.9899 1.004 1.004 0 00-.7058.304l-6.05 6.26-2.516-2.5857z'/></svg>";
  content: "";
  height: 1em;
  width: 1em;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url($svg);
}

.container {
  background-color: #f8fafc;
  padding: 0 3rem;
  font-size: 1rem;
  color: #4b6ea1;
  margin-top: 0px;

  @media (max-width: 600px) {
    padding: 0;
  }
}

.paymentOptionButtons {
  button {
    margin: 0.2rem;
    background-color: $lightGray;
    border-color: $lightGray;
    color: $darkGray;
    position: relative;

    &:hover {
      background-color: darken($lightGray, 10%);
      border-color: darken($lightGray, 10%);
      color: darken($darkGray, 10%);
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.currencyDropdownContainer {
  max-width: 1220px;
  margin: 0 auto;
}

.savingsContainer {
  white-space: nowrap;
  pointer-events: none;
  position: absolute;
  bottom: -40px;
  width: 200px;
  left: -50px;
  text-align: left;

  @media (max-width: 600px) {
    bottom: unset;
    top: -65px;
    left: 0px;
  }
}

.savingsSvg {
  @media (max-width: 600px) {
    transform: scaleX(-1) rotate(130deg) translate(14px, -58px);
  }
}

.savingsText {
  background: $highlightBorder;
  color: black;
  text-align: center;
  border-radius: 4px;
  width: fit-content;
  padding: 0 0.5rem;
}

.currencyPickerLabel {
  font-family: $font-family-gilroy-bold;
}

.currencyPicker {
  button,
  button:hover,
  button:active,
  button:focus {
    color: $blue !important;
    background-color: unset !important;
    border-color: transparent !important;
    padding: 4px;
    margin: 16px;
  }
}

.packageCardContainer {
  @media (max-width: 600px) {
    padding: 0;
  }
}

.packageCard {
  max-width: 362px;
  margin: 2rem 1rem;
  flex-grow: 1;
  color: $body-color;
  border-radius: 4px;
  border: 3px solid $lightGray;
  box-shadow: none;
  position: relative;
  text-align: left;

  .rowTitle {
    font-size: 16px;
    font-family: $font-family-gilroy-bold;
    text-decoration: underline;
    margin: 1rem 0;
  }

  @media (max-width) {
    margin: 2rem 0;

    [class~="card"] {
      padding: 0.75rem 1rem;
    }
  }

  [class~="card-item"] {
    flex: 0 0 50%;
  }

  &[class~="highlight"] {
    border: 3px solid $highlightBorder;
    background-color: #e7f4f1;
    position: relative;

    [class~="highlight-text"] {
      white-space: nowrap;
      font-family: $font-family-gilroy-bold;
      font-size: 13px;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $highlightBorder;
      padding: 0 8px 4px;
      border-radius: 4px;

      &::before {
        @include checkMarkSvg(black);
        margin-bottom: -2px;
        margin-right: 0.4rem;
      }
    }
  }

  .packageCardFooter {
    position: absolute;
    bottom: -25px;
    left: 0;
    color: #9e9e9e;

    &.multiline {
      bottom: -45px;
    }
  }
}

.packageCardTitle {
  font-family: $font-family-gilroy-bold;
  font-size: 28px;
  text-align: center;
  color: #333;
}

.packageCardPricing {
  text-align: center;
  [class~="currency"] {
    font-size: 22px;
  }

  [class~="price"] {
    font-family: $font-family-gilroy-bold;
    font-size: 48px;
    padding: 0 6px;
  }

  [class~="period"] {
    color: #7a7a7a;
  }
}

.upgradeButton {
  width: 100%;
  justify-content: center;
}

.metaDataList {
  padding-left: 0.2rem;
  margin-top: 1rem;
  list-style: none;

  li {
    display: flex;
    padding: 4px 0;
    .checkMark::before {
      @include checkMarkSvg();
      margin-right: 0.5rem;
      margin-top: 4px;
    }
  }
}

.betterOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.75rem 0;
  max-width: 800px;
  margin: 0 auto;
  gap: 1rem;

  .betterOptionsText {
    margin: 0 1rem;
    flex: 1;
    [class~="title"] {
      font-size: 24px;
      color: $body-color;
      margin: 0;
    }
    p {
      margin: 0;
    }
  }

  @media (max-width: 840px) {
    flex-direction: column;
    flex-wrap: wrap;

    .betterOptionsText {
      text-align: center;
      margin: 1rem 0;
    }

    .betterOptionsButtonContainer {
      display: flex;
      justify-content: center;
      width: 100%;

      button {
        width: 100%;
        max-width: 300px;
      }
    }
  }
}

.diamondIcon {
  height: 3rem;
  width: 3rem;
  background-color: #3333ff;
  color: white;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricingComparisonContainer {
  display: grid;
  grid-template-columns: 1.5fr 0.8fr 0.8fr 0.8fr;
  grid-auto-rows: 1fr;
  gap: 9px 10px;
  grid-auto-flow: row;
}

.pricindComparisonDataContainer {
  margin-top: -64px;
  padding-bottom: 60px;
  text-align: left;
}

.pricingComparisonTitle {
  font-family: $font-family-gilroy-bold;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);

  div {
    align-items: center;
    display: flex;
  }

  [class~="title"] {
    font-size: 26px;
  }

  [class~="package"] {
    font-size: 18px;
    width: 150px;
    justify-content: center;
    border-radius: 4px;

    &[class~="highlight"] {
      background-color: $darkHighlight;
    }
  }
}

.pricingComparisonSection {
  font-size: 26px;

  div {
    align-items: center;
    display: flex;
  }

  .comparisonSectionTitle {
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        font-size: 16px;
      }
    }
  }

  [class~="empty"] {
    width: 150px;
    justify-content: center;

    &[class~="highlight"] {
      background-color: $lightHightlight;
      border-left: 2px solid $darkHighlight;
      border-right: 2px solid $darkHighlight;
    }
  }
}

.pricingComparisonRow {
  div {
    align-items: center;
    display: flex;

    @media (max-width: 767px) {
      &.fakeRow {
        display: none;
      }
    }
  }

  [class~="title"] {
    padding-left: calc(30px + 0.5rem + 15px);
    @media (max-width: 767px) {
      padding-left: 0.5rem;
    }
  }

  [class~="detail"] {
    width: 150px;
    justify-content: center;

    &[class~="highlight"] {
      background-color: $lightHightlight;
      border-left: 2px solid $darkHighlight;
      border-right: 2px solid $darkHighlight;

      @media (max-width: 767px) {
        border: 2px solid $darkHighlight;
      }
    }
  }

  [class~="check-mark"] {
    @include checkMarkSvg();
  }
}

.toggleButton {
  font-size: 30px;
  padding-left: 1rem;
}

.sticky {
  position: sticky;
  top: calc(100% - 60px);
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.16);
  background-color: white;
  z-index: 9999;

  [class~="package"] {
    width: 150px;
    justify-content: center;
    &[class~="highlight"] {
      background-color: $darkHighlight;
    }

    [class~="btn"] {
      padding: 0.2rem;
      width: 100%;
    }
  }

  div {
    align-items: center;
    display: flex;
  }
}

.nowrap {
  flex-wrap: nowrap;
}
