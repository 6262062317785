$font-family-gilroy: Gilroy, sans-serif;
$font-family-gilroy-light: GilroyLight, sans-serif;
$font-family-gilroy-bold: GilroyBold, sans-serif;
$font-family-gilroy-medium: GilroyMedium, sans-serif;

// #### COLORS
// ### GRAY
$gray: #c5c5c5;

$cool-gray: #f2f2f2;
$cool-gray-4: #c8c8c8;
$cool-gray-7: #a0a0a0;
$cool-gray-9: #7a7a7a;
$cool-gray-11: #53565a;

$light-gray: #e6e7e8;

$lighter-gray: #f7f7f7;
$lighter-gray-2: #fafafa;

// ### BLUE
$blue: #3333ff;
$blue-hover: #3030db;
$navy-blue: #0f3e82;

$font-size-base: 0.875rem;

$font-weight-bold: 600;

// ### GREEN
$green: #31c772;
$done-green: #18ab7f;

$yellow: #fecf84;
$orange: #ffb644;
$red: #e03e52;
$gray-600: #757575;
$info: #c8d8eb;

$primary: $blue;
$secondary: $gray-600;
$success: #18ab7f;
$successLight: #b5e3d8;
$warning: #ffbf1d;
$danger: $red;
$dangerLight: #fbccd1;

$body-color: #333333;

$headings-margin-bottom: 17px;
$headings-font-weight: 300;
$headings-line-height: 1.2;
$headings-color: $blue;

$paragraph-margin-bottom: 0.625rem;

$header-height: 60px;
$sidebar-width: 260px;
$sidebar-visible-screen-width: 767px;

$navbar-padding-y: 0;
$navbar-padding-x: 0;

$modal-md: 600px;

$dark: #343a40;
$gray-300: #dee2e6;

// ############ FORMS & CONTROLS ############

$inputPadding: 16px;
$smallInputPadding: 8px;
$inputContainerVerticalMargin: 12px;
$inputContainerHorizontalMargin: 0px;

// #### LIGHT (DEFAULT)
$labelColor: #0f3e82;
$inputBgColor: white;
$inputBorderColor: #c5c5c5;
$inputTextColor: #333333;
$inputPlaceholderColor: #7a7a7a;

// #### DARK
$labelColor-dark: #333333;
$inputBgColor-dark: #f2f2f2;
$inputTextColor-dark: #111113;
$inputPlaceholderColor-dark: #a0a0a0;

// #### ERROR
$inputInvalidBgColor: #fff2f4;
$inputInvalidTextColor: #e03e52;

// #### VISUALS
$beneficiaryAvatarSize: 40px;
