@import 'theme/_variables';

.container {
    .titleRow {
        display: flex;

        .title {
            display: flex;
            align-items: center;
        }

        .titleToggle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
            color: $blue;

            .titleRightDescriptionText {
                color: $cool-gray-9;
                font-size: 16px;
                margin-right: 1rem;
                font-family: $font-family-gilroy;
            }
        }
    }

    .body {
        position: relative;
        animation: fadein 0.5s;
        font-size: 14px;

        .innerBody {
            padding: 0.5rem 0;
        }
    }

    &.closed {
        .body {
            overflow: hidden;
            max-height: 0px;
            border-top: none;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.4;
        box-shadow: 0px 4px 10px 0px #00000016;
    }

    .title {
        display: flex;
        align-items: center;
        padding-right: 2.5rem;
    }

    .titleToggle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        color: $blue;
    }

    .bodyToggle {
        text-align: center;
        margin: 1rem;
    }

    .button {
        padding: 1rem 0;
        background-color: unset;
        border: none;
        position: relative;
        text-align: inherit;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

$primary: #8cc63e;
$checkmarkColor: white;
$size: 20px;
$checkmarkFat: 3px;

.circle {
    position: relative;
    background: $primary;
    border-radius: 50%;
    width: $size;
    height: $size;
}

.checkmark {
    position: absolute;
    transform: rotate(45deg) translate(-50%, -50%);
    left: 35%;
    top: 45%;
    height: $size/3;
    width: $size/6;
    border-bottom: $checkmarkFat solid $checkmarkColor;
    border-right: $checkmarkFat solid $checkmarkColor;
}
