@import "theme/_variables";

@mixin colorize($color, $hover-color: $color, $text-variant-color: $color) {
    color: white;
    background: $color;

    &:focus {
        outline: $color;
    }
    &:hover {
        background: $hover-color;
        border-color: $hover-color;
    }
    &.text {
        background: transparent;
        font-family: $font-family-gilroy-bold;
        border: transparent;
        color: $text-variant-color;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;

        &:hover:not(:disabled) {
            color: $hover-color;
            background: transparent;
        }
    }
    &.outline {
        color: $color;
        background: white;
        border: 1px solid $color;
        font-family: $font-family-gilroy-bold;

        &:hover:not(:disabled) {
            color: $hover-color;
            background: white;
            border-color: $hover-color;
        }
    }
}
.button {
    color: #fff;
    background-color: $blue;
    font-family: $font-family-gilroy-bold;
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    display: inline-flex;

    &.danger {
        @include colorize($red, $red);
    }
    &.primary {
        @include colorize($blue, $blue-hover);
    }
    &.secondary {
        @include colorize($cool-gray-4, $cool-gray-9, $body-color);
    }
    &.lightgray {
        @include colorize($cool-gray-4, $cool-gray-9, $cool-gray-4);
    }
    &.success {
        @include colorize(green);
    }
    &.withIcon {
        display: inline-flex;
        align-items: center;
        .faIcon:not(.iconOnly) {
            margin-right: 0.5rem;
            &.right {
                margin-right: 0;
                margin-left: 0.5rem;
            }
        }
    }
    &:disabled:not(.loading) {
        cursor: not-allowed;
        color: #7a7a7a;
        border: 1px solid transparent;
        &:not(.text) {
            background: $cool-gray-4;
        }
    }
    &.loading {
        opacity: 0.65;
        cursor: default;
    }
}

.active {
    border-bottom: 2px solid $navy-blue;
}
