.infoIconContainer {
  padding: 0;
  background: none;
  border: none;
  margin-left: 6px;
  color: #7a7a7a;

  button {
      display: inherit;
  }

  &:focus {
      outline: none;
  }
}
