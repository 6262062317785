@import "theme/_variables";
@import "~bootstrap/scss/bootstrap.scss";

@font-face {
    font-family: "Gilroy";
    src: url("assets/fonts/Gilroy-Regular.woff") format("woff"),
        url("assets/fonts/Gilroy-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "GilroyLight";
    font-weight: 300;
    src: url("assets/fonts/Gilroy-Light.woff") format("woff"),
        url("assets/fonts/Gilroy-Light.woff2") format("woff2");
}

@font-face {
    font-family: "GilroyMedium";
    font-weight: 300;
    src: url("assets/fonts/Gilroy-Medium.woff") format("woff"),
        url("assets/fonts/Gilroy-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "GilroyBold";
    font-weight: 700;
    src: url("assets/fonts/Gilroy-Bold.woff") format("woff"),
        url("assets/fonts/Gilroy-Bold.woff2") format("woff2");
}

html,
body,
#root {
    background-color: #f8fafc;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 20px;
}

body {
    font-family: $font-family-gilroy !important;

    &.overflow-hidden {
        overflow: hidden;
    }
}

.custom-control {
    margin-top: 10px;
    margin-bottom: 10px;
}

#root {
    display: flex;
    flex-direction: column;
}

.nav-tabs .nav-item:hover {
    border-top-color: rgba($blue, 0.5) !important;
}

.nav-link.disabled {
    color: white;
    opacity: 0.2;
}

.container-fluid {
    background-color: #fcfcfc;
}

.modal-drawdown .modal-dialog {
    @media (min-width: 576px) {
        max-width: 800px;
    }
}

.card {
    border-radius: 20px;
    box-shadow: 0 4px 18px #0000001a;
    border: none;
}

// React Select
.amnis__control {
    min-height: 35px !important;
}

.amnis__control .amnis__indicator {
    padding: 6px;
}

.amnis__menu {
    z-index: 2;
}

.form-label {
    color: #54565b;
    font-size: 0.875rem;
    font-family: $font-family-gilroy-bold;
}

.card-body {
    padding: 1.25rem 2.1875rem;
}
.even .card-body {
    padding: 1.25rem;
}
