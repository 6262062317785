@import 'theme/_variables';

@mixin checkMarkSvg($color: "limegreen") {
  $svg: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$color}' viewBox='0 0 13 10'><path fill='#{$color}' d='M2.6628 3.9784a1 1 0 00-1.44 1.38l3.226 3.3358a1.0001 1.0001 0 00.72.31 1.0002 1.0002 0 00.72-.3l6.78-7A1.0055 1.0055 0 0012.9529.99a1.0046 1.0046 0 00-1.0183-.9899 1.004 1.004 0 00-.7058.304l-6.05 6.26-2.516-2.5857z'/></svg>";
  content: "";
  height: 1em;
  width: 1em;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url($svg);
}

var {
  font-style: unset;
}

.wrapper {
  width: 100%;
  overflow: auto;
}

.container {
  position: relative;
  min-width: 700px;
}

.footer {
  bottom: 0;
  background-color: white;
  z-index: 9998;
}

.header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9999;
}

.header,
.footer {
  box-shadow: 2px 2px 10px 0px #00000029;
  padding: 2rem;
  max-width: 100%;
  margin: 1.5rem 0;
  font-family: $font-family-gilroy-bold;

  .label {
    display: flex;
    align-items: center;
    font-size: 28px;
  }
}

.footer {
  padding: 0.5rem;
}

.accordionTitle {
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    background-color: #DFE8F3 !important;
    margin-bottom: 4px;

  .sectionTitle {
    width: 100%;
    text-align: left;
    padding-left: 1rem;
    grid-column: 1/5;
    font-family: $font-family-gilroy-bold;
    font-size: 26px;
    padding: 0.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    a {
      font-size: 14px;
      width: fit-content;
    }
  }
}

.gridContainer {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  // grid-auto-rows: 1fr; 
  grid-template-columns: 1.5fr 0.8fr 0.8fr 0.8fr; 
  gap: 9px 10px;
  font-size: 16px;

  .button {
    display: block;
    width: 70%;
    margin: 0.25rem auto;
  }

  .label {
    text-align: left;
    display: flex;
    align-items: flex-start;

    a {
      color: $blue;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    p {
      margin: 0;
    }
  }

  .value {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subtitle {
    font-size: 18px;
    background: #DFE8F3;
    margin: 0 -10px;
  }

  .checkMark {
    @include checkMarkSvg();
  }

  .divider {
    height: 0;
    border-bottom: 1px solid #DFE8F3;
  }
}
