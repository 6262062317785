.logo
  display: flex
  justify-content: center
  align-items: center

.imageBig
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  max-width: 300px

.imageSmall
  display: inline-block
  max-width: 80px
